import React, { Fragment, useEffect } from 'react'
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from '@material-ui/core'
import { ArrowForwardIos, Print } from '@material-ui/icons'
import dayjs from 'dayjs'

import { Heading } from '../../../components/Text/Heading'
import { makeStyles } from '@material-ui/core/styles'

import { useStoreActions, useStoreState } from '../../../store/store.hooks'
import { useTranslation } from 'react-i18next'
import { normalizeLanguage } from '../../../util'
import { DownloadAttachments } from '../../../components'

const useStyles = makeStyles((theme) => ({
  dashBoardContainer: {
    padding: '60px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    flexDirection: 'column',
    backgroundColor: '#fff',
    borderRadius: '15px',
    textAlign: 'left',
    [theme.breakpoints.down('xs')]: {
      padding: '20px',
    },
  },
  complaintDetail: {
    marginTop: 10,
    marginBottom: 20,
    padding: '0 1rem',
    color: '#1E2864',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: '0 20px',
    },
  },
  patientInfoContainer: {
    borderRadius: 15,
    border: '1px solid #C1C7DF',
    boxShadow: '0 10px 20px 0 rgba(100,115,175,0.2)',
  },
  patientInfoUpper: {
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid #C1C7DF',
    padding: 20,
  },
  avatar: {
    width: 80,
    height: 80,
    boxShadow: '0 5px 10px 0 rgba(100,115,175,0.4)',
    marginRight: 15,
  },
  patientName: {
    margin: 0,
    fontSize: 24,
    fontWeight: 300,
  },
  patientInfoLower: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    borderBottom: '1px solid #C1C7DF',
  },
  complaintDetailContainer: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
  },
  complaintDetailInput: {
    width: '100%',
    borderRadius: 15,
  },
  label: {
    fontSize: 12,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: '#6473AF',
  },
  complaints: {
    fontSize: 24,
    fontWeight: 'bold',
  },
  complaintDetailText: {
    whiteSpace: 'pre-wrap',
    fontSize: 20,
    marginTop: 5,
  },
  disclaimerText: {
    fontSize: '1rem',
    marginTop: '1rem',
    textAlign: 'center',
  },
  doctorFeedback: {
    margin: '30px 0',
  },
  download: {
    display: 'flex',
    textDecoration: 'none',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#E0E3EF',
    padding: '10px 20px',
    maxWidth: 350,
    marginTop: 50,
    borderRadius: 15,
    color: '#6473AF',
  },
  consultancyTimingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 1rem',
    width: '100%',
  },
  consultancyTiming: {
    color: '#6473AF',
    fontSize: 12,
    fontWeight: 'bold',
  },
  printButton: {
    float: 'left',
  },
  printAllButton: {
    marginLeft: theme.spacing(5),
    '@media print': {
      display: 'none',
    },
  },
  printIcon: {
    color: 'rgb(30,40,100)',
  },
  requestContainerBox: {
    marginTop: '2rem',
    width: '100%',
  },
}))

const Consultancies = () => {
  const fetchRequests = useStoreActions(
    (actions) => actions.request.fetchRequests
  )
  const requests = useStoreState((state) => state.request.requests)?.filter(
    (request: any) => request.calls.length > 0
  )
  const classes = useStyles()
  const {
    t,
    i18n: { language: lng },
  } = useTranslation(['consultancies', 'requestImgDownload'])
  const language = normalizeLanguage(lng)

  useEffect(() => {
    fetchRequests({})
  }, [fetchRequests])

  const getFormattedCallLength = (callLength: number) => {
    const callLengthInSeconds = Math.round(callLength)
    let minutes = Math.floor(callLengthInSeconds / 60).toString()
    let seconds = (callLengthInSeconds % 60).toString()

    // if minutes or seconds have only one digit, we have to prepend it with a 0 to display the minutes count properly
    if (minutes.length === 1) {
      minutes = `0${minutes}`
    }
    if (seconds.length === 1) {
      seconds = `0${seconds}`
    }

    return `${minutes}:${seconds}`
  }

  const getFormattedCallStartedDate = (startDate: string) => {
    return dayjs(startDate).format('YYYY.MM.DD HH:mm')
  }

  const printPage = () => {
    window.print()
  }

  if (!requests) {
    return <CircularProgress />
  }

  return (
    <Grid item xs={12} className={classes.dashBoardContainer}>
      <Box display="flex" justifyContent="center" width="100%">
        <Heading>{t<string>('heading')}</Heading>
        {requests.length > 0 && (
          <Button className={classes.printAllButton} onClick={printPage}>
            <Print className={classes.printIcon} />
          </Button>
        )}
      </Box>

      <Box>
        <Typography variant="h6" className={classes.disclaimerText}>
          {t('disclaimer')}
        </Typography>

        {language === 'hu' && (
          <>
            <Typography variant="h6" className={classes.disclaimerText}>
              {t('disclaimerLine2')}
            </Typography>

            <Typography variant="h6" className={classes.disclaimerText}>
              {t('disclaimerLine3')}
            </Typography>
          </>
        )}
      </Box>

      {requests.map((request: any) => (
        <Box key={request?.id} className={classes.requestContainerBox}>
          <div className={classes.consultancyTimingContainer}>
            <span className={classes.consultancyTiming}>
              {getFormattedCallStartedDate(request?.calls[0]?.callStartedAt)}
            </span>
            <span className={classes.consultancyTiming}>
              {getFormattedCallLength(request?.calls[0]?.callSeconds)}
            </span>
          </div>
          <div className={classes.complaintDetail}>
            <div className={classes.patientInfoContainer}>
              <div className={classes.patientInfoUpper}>
                <Avatar
                  alt="user"
                  src={request?.provider?.avatarUrl}
                  className={classes.avatar}
                />
                <div>
                  <h3 className={classes.patientName}>
                    <strong>{`${request?.provider?.namePrefix || ''} ${
                      request?.provider?.lastName
                    } ${request?.provider?.firstName}`}</strong>
                  </h3>
                </div>
              </div>
              <div className={classes.patientInfoLower}>
                <span className={classes.label}>
                  {t<string>('professionalLabel')}
                </span>
                <span className={classes.complaintDetailText}>
                  {request?.providerResponse}
                </span>
              </div>
              <div className={classes.complaintDetailContainer}>
                <span className={classes.label}>
                  {t<string>('complaintLabel')}
                </span>
                <span className={classes.complaints}>
                  {request?.complaints
                    .map((complaint: any) => complaint.name)
                    .join(', ')}
                </span>
                <span className={classes.complaintDetailText}>
                  {request?.description}
                </span>
                <DownloadAttachments
                  alreadyAttachedFiles={request.attachments}
                  shortenFileNames={true}
                />
              </div>
            </div>
          </div>
        </Box>
      ))}
    </Grid>
  )
}

export { Consultancies }
